import React from 'react';
import {Grid} from '../Grid';
import {Wrapper, ContentWrapper} from './styledComponent';

function replaceAt(string){
    return string.replace('(thisisafuckingat)', '@');
}

function replaceDot(string){
    return string.replace('(thisisafuckingdot)', '.');
}

const email = 'hello(thisisafuckingat)hypnotherapie-sophie(thisisafuckingdot)be';
const parsedEmail = replaceDot(replaceAt(email));

function Footer(){
    return(
        <Wrapper id="footer">
            <Grid>
                <ContentWrapper>
                    <div>
                        <a href={`mailto:${parsedEmail}`}>{parsedEmail}</a>
                        <a href="tel:0032477277965">+32 477 277 965</a>
                        <a href="https://www.facebook.com/HypnOseSophie" target="_blank" rel="noopener noreferrer">facebook</a>
                    </div>
                    <p>
                        J'aurai le plaisir de vous accueillir sur rendez-vous <br />
                        soit au <a href="https://goo.gl/maps/LocUkEez57YrR3Sf8" target="_blank" rel="noopener noreferrer">65, Rue de Shoppach, à 6700, Arlon</a> <br />
                        ou au <a href="https://goo.gl/maps/g91tNUoBEazzJwqC6" target="_blank" rel="noopener noreferrer">5, Place Dailly, à 1030, Bruxelles</a>
                    </p>
                </ContentWrapper>
            </Grid>
        </Wrapper>
    )
}

export default Footer;