import React from 'react';
import { Grid } from '../components/Grid';
import aboutImg from '../assets/images/about-image.jpg';
import styled from 'styled-components';

const StyledGrid = styled(Grid)`
    display: flex;
    margin-top: 64px;
    margin-bottom: 64px;
    justify-content: center;
    img{
        width: 30%;
        max-height: 300px;
        object-fit: cover;
    }
    div{
        margin-left: 24px;
        max-width: 600px;
        p {
            font-size: 18px;
            margin-bottom: 24px;
        }
        h3{
            font-weight: bold;
            font-size: 20px;
        }
    }
    @media screen and (max-width: 924px){
        flex-direction: column;
        margin-top: 24px;
        margin-bottom: 24px;
        img {
            width: 100%;
        }
        div{
            margin-left: 0;
            margin-top: 24px;
            max-width: unset;
            width: 100%;
        }
    }
`;

function About(){
    return(
        <StyledGrid>
            <img src={aboutImg} alt="Sophie Denis"/>
            <div>
                <p>
                    De plus en plus de personnes font appel à l’hypnose pour régler leurs difficultés face au stress, à leurs phobies, à leurs angoisses.
                </p>
                <p>
                    Nous sommes peu nombreux à connaître cette thérapie. Elle nous semble mystérieuse. D’autant plus qu’elle nous amène à travailler sur notre subconscient car c’est là que sont ancrées nos peurs à l’origine de nos situations de vie.
                </p>
                <p>
                    Si nous sommes prêts à faire le pas vers l’hypnose, il est essentiel de faire appel à un thérapeute en qui nous pouvons avoir confiance car celle-ci est primordiale pour que la technique porte ses fruits.
                </p>
                <p>
                    Lorsque Sophie Denis m’a demandé d’écrire quelques mots sur son site, je n’ai pas hésité un instant.
                </p>
                <p>
                    Je connais Sophie depuis 30 ans. Elle était alors une jeune animatrice radio. Par son sourire, ses mots et son ton doux et rassurant, elle marquait déjà son envie d’être utile aux auditeurs qu’elle aimait écouter et encourager.
                </p>
                <p>
                    C’est ensuite avec ses 4 enfants qu’elle a pratiqué l’art de soigner, guider et de réconforter.
                </p>
                <p>
                    Aujourd’hui qu’elle est devenue hypnothérapeute pour mettre sa voix, son expérience de vie et ses multiples formations en développement personnel au service des autres, je me dis que les personnes qui vont venir la consulter et lui demander de l’aide ont de la chance.
                </p>
                <p>
                    Sa bienveillance, son écoute, son expérience, ses compétences et sa douceur vont faire des merveilles dans l’art de l’hypnose.
                </p>
                <p>
                    L’hypnose est comme une danse qui se pratique à deux. Plus la confiance est là, plus vous serez réceptif et détendu, plus le résultat sera efficace.
                </p>
                <p>
                    Sophie est une de ces personnes de confiance qui nous font remercier la vie d’avoir croisé leur chemin.
                </p>
                <p>
                    Je vous souhaite de la rencontrer.
                </p>
                <h3>
                    Marc Vossen
                </h3>
                <h3>
                    Directeur général NRJ et Nostalgie Groupe
                </h3>
            </div>
        </StyledGrid>
    )
}

export default About;