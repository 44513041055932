import React from 'react';
import {Route, useLocation} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Header from './components/Header';
import Footer from './components/Footer';

const NoMatchPage = () => {
    return (
        <h1>404 - Not found</h1>
    );
};


function AppRouter(){
    const {pathname} = useLocation();
    const paths = ['/', '/about', '/contact'];
    return (
        <>
            {paths.includes(pathname) ? (
                <>
                    <Header />
                    <Route path="/" exact component={Home} />
                    <Route path="/about" exact component={About} />
                    <Footer />
                </>
            ) : (
                <Route component={NoMatchPage} />
            )}
        </>
    )
}

export default AppRouter;