import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 60px;
    width: 100%;
    background: #3772FF;
    position: fixed;
    z-index: 30000;
    top: 0;
    box-shadow: 0 16px 32px 0 rgba(0,0,0,0.10);
    p {
        font-size: 18px;
        color: #ffffff;
        text-align: center;
        line-height: 60px;
        span {
            font-weight: bold;
        }
    }
    @media screen and (max-width: 460px){
        p{
            font-size: 16px;
        }
    }
`