import styled from 'styled-components';
import quotemarks from '../../assets/images/quotemarks.png';

export const Wrapper = styled.div`
    width: 38.54%;
    @media screen and (max-width: 768px){
        width: 80%;
    }
    position: relative;
    &:before {
        content: "";
        width: 154px;
        height: 129px;
        display: block;
        position: absolute;
        background: url(${quotemarks}) center no-repeat;
        background-size: cover;
        left: -40px;
        top: -40px;
    };
    h2 {
        font-size: 36px;
        font-weight: bold;
        @media screen and (max-width: 768px){
            font-size: 28px;
        }
    };
    p{
        font-size: 24px;
        font-style: italic;
        margin-top: 32px;
        @media screen and (max-width: 768px){
            font-size: 22px;
        }
    }
`