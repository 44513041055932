import styled, { keyframes, css } from 'styled-components';
import {Link}from 'react-router-dom';

const animate = keyframes`
    0%{
        transform: rotate(-22deg);
    }
    100%{
        transform: rotate(22deg);
    }
`

export const Wrapper = styled.div`
    display: flex;
    ${({showBanner}) => showBanner && css`
        margin-top: 60px;
    `}
    justify-content: space-between;
    a {
        text-decoration: none;
        color: #000000;
    };
    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`

export const LogoWrapper = styled.div`
    padding: 32px 0;
    display: flex;
    h1, h2 {
        font-size: 32px;
    };
    h1 {
        font-weight: bold;
    };
    svg{
        align-self: center;
        #pendule{
            transform-origin: center bottom;
            animation: ${animate} 1.5s ease-in-out infinite alternate;
        }
    }
    div{
        margin-left: 16px;
    }
    @media screen and (max-width: 768px){
        display: flex;
        justify-content: center;
    }
`

export const LinkWrapper = styled.div`
    display: flex;
    align-self: center;
    @media screen and (max-width: 768px){
        a:first-child {
            margin-left: 0;
        }
        margin-bottom: 24px;
    }
`

const linkStyle = css`
text-decoration: none;
    font-size: 18px;
    margin-left: 24px;
    color: #000000;
    position: relative;
    &:after{
        content: "";
        position: absolute;
        display: block;
        bottom: -3px;
        height: 2px;
        width: 0%;
        background: #000000;
        transition: .3s cubic-bezier(.3,1,.3,1);
    }
    &:hover {
        &:after{
            width: 100%;
        }
    }
    ${({ isactive }) => isactive && css`
        &:after{
            width: 100%;
        }
    `}
`

export const StyledLink = styled(Link)`
    ${linkStyle}
`

export const StyledA = styled.a`
    ${linkStyle}
`