import React from 'react';
import {Wrapper} from './styledComponent';

function Blockquote({content, author}){
    return(
        <Wrapper>
            <h2>{content}</h2>
            <p>{author}</p>
        </Wrapper>
    )
}

export default Blockquote;