import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {ReactComponent as Logo} from '../../assets/images/logo.svg'
import {Wrapper, LogoWrapper, LinkWrapper, StyledLink, StyledA} from './styledComponent';
import {Grid} from '../Grid';
import {home} from '../../content/home';
import Banner from '../Banner';


function Header(props){
    const {pathname} = useLocation();
    const showBanner = home.banner.show && pathname === '/';

    return (
        <>
            {showBanner && (
                <Banner content={home.banner.content} />
            )}
            <Grid>
                <Wrapper showBanner={showBanner}>
                    <Link to="/">
                        <LogoWrapper>
                            <Logo />
                            <div>
                                <h1>Sophie Denis</h1>
                                <h2>Hypnothérapeute</h2>
                            </div>
                        </LogoWrapper>
                    </Link>
                    <LinkWrapper>
                        <StyledLink to="/about" isactive={pathname === "/about"}>À Propos</StyledLink>
                        <StyledA href="mailto:hello@hypnotherapie-sophie.be" isactive={pathname === "/contact"}>Contactez-moi</StyledA>
                    </LinkWrapper>
                </Wrapper>
            </Grid>
        </>
    )
}

export default Header;