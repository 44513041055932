import styled, {css} from 'styled-components';

export const Wrapper = styled.section`
    width: 100%;
    background: ${({background}) => background};
    padding: 64px 0;
`

export const ContentWrapper = styled.div`
    width: 56.04%;
    margin: 0 auto;
    @media screen and (max-width: 768px){
       width: 85.69%;
    }
    h1{
        font-size: ${({isIntro}) => isIntro ? '64px' : '48px'};
        color: ${({theme}) => theme === 'dark' ? '#FFFFFF' : "#000000"};
        ${({isIntro}) => isIntro && css`
            color: #3F84E5;
        `}
        @media screen and (max-width: 768px){
            font-size: ${({ isIntro }) => isIntro ? '48px' : '36px'};
        }
    };
    p {
        margin-top: 24px;
        font-size: 21px;
        line-height: 28px;
        color: ${({ theme }) => theme === 'dark' ? '#FFFFFF' : "#000000"};
        
        a {
            color: ${({ theme }) => theme === 'dark' ? '#000000' : "#3590F3"};
        }
    }
`