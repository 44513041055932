import React from 'react';
import './App.css';
import {BrowserRouter, Switch} from 'react-router-dom'
import AppRouter from './AppRouter';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <AppRouter />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
