import React from 'react';
import {Wrapper} from './styledComponent';

function Banner({content}){
    return (
        <Wrapper>
            <p dangerouslySetInnerHTML={{
                __html: content
            }}/>
        </Wrapper>
    )
}

export default Banner;