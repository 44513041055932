import heroImage from '../assets/images/hero-image.jpg';

export const home = {
    banner: {
        show: true,
        content: "<span>COVID-19</span>: Possibilité de consultation en visio-conférence",
    },
    hero: {
        imgSource: heroImage,
        imgAlt: 'Chemin de plage',
        quote: {
            content: "L'hypnose, c'est une relation pleine de vie qui a lieu dans une personne et qui est suscitée par la chaleur d'une autre personne.",
            author: "Milton Hyland Erickson",
        }
    },
    sections: [
        {
            isIntro: true,
            theme: "light",
            title: "Ouvrez les portes </br> de votre vie…",
            paragraphs: [
                "Au delà des évènements constitutifs de nos vies, notre histoire est celle de nos états émotionnels et aussi de nos états de conscience. Ce dont nous nous souvenons depuis le début de nos existences ce n'est pas tant les faits bruts, mais bien plutôt les sensations qui nous ont étreint en les vivant par saisissements, peurs, angoisses, joies, envies, colères, plaisirs... Ces émotions ont modelé nos vies, et continuent de le faire. Elles ont généré nos croyances et nos limites.",
                "Ainsi, l'existence en apparence la plus modeste, peut être douce et riche, si la sérénité, la réconciliation avec nous-mêmes et les autres, conditionnent notre état d'être.",
                "Ainsi, la vie la plus excitante, la plus privilégiée, peut se révéler au final stérile et amère, si nous ne sommes en paix ni avec nous-même ni avec les autres."
            ]
        },
        {
            theme: "light",
            background: "#AFDFFC",
            title: 'L’hypnose Ericksonnienne c’est…',
            paragraphs: [
                "Les thérapies brèves sont des outils merveilleux de résolution de nos problèmes, parce qu'elles n'exigent pas de nous de mettre un nom sur ceux-ci, ni d'en connaitre l'origine exacte. Ce sont des thérapies pragmatiques, qui recherchent le chemin intérieur qui nous mène à la solution, en poussant les portes de notre imaginaire, de notre 'inconscient', de tout ce que nous ne savons pas que nous savons... Lorsque nous débranchons le 'facteur critique', alors nous est donné l'accès à un pouvoir insoupçonné de guérison intérieure, de résolution de conflits, de libération personnelle.",
            ]
        },
        {
            theme: "dark",
            background: "#5FBFF9",
            title: 'L’hypnose pour qui ?',
            paragraphs: [
                "Ludique et créative pour les enfants, accessible de 7 à 107 ans, quel que soit votre âge, si VOUS avez le désir de changer, VOUS le pouvez !",
                "Si vous craignez de ne pouvoir être hypnotisé, si vous êtes anxieux, sachez qu’il existe de nombreuses techniques d’induction hypnotique et différents niveaux de transe. Je serai là pour répondre à toutes vos interrogations, et adapter la technique à vos besoins personnels. La relation de confiance qui s’établira entre nous est absolument déterminante, et indispensable au bon déroulement des séances."
            ]
        },
        {
            theme: "dark",
            background: "#3590F3",
            title: 'L’hypnose pour quelles raisons...',
            paragraphs: [
               'Concrètement, l’hypnose peut s’avérer efficace dans de nombreuses indications : stress, anxiété, angoisse, état dépressif, accompagnement du deuil, traumatismes divers, peurs, phobies, mais également pour divers objectifs, pertes de poids, <a href="https://stoptabacsophie.wixsite.com/stoptabac" target="_blank">arrêt du tabac</a> et/ou de toutes autres addictions, mais aussi dans le coaching, la confiance en soi, les préparations aux examens scolaires, entretiens d’embauche, permis de conduire, compétitions sportives, mais aussi troubles du sommeil, les allergies, l’énurésie et bien d’autres choses encore, comme les vies antérieures et plus encore…'
            ]
        },
        {
            theme: "dark",
            background: "#3681F9",
            title: 'L’hypnose et mes outils :',
            paragraphs: [
               '• Hypnose Ericksonnienne',
               '• Hypnose Conversationnelle Stratégique',
               '• Thérapie Brève Systémique',
               '• Thérapie Brève du Traumatisme',
               '• Sexologie et sexoanalyse',
               '• Psychologie Positive du Couple',
               '• Gestion du Poids - Chronobiologie',
               '• Sevrage Tabagique',
            ]
        }
    ]
}