import React from 'react';
import {home} from '../content/home';
import Hero from '../components/Hero';
import Blockquote from '../components/Blockquote';
import ContentSection from '../components/ContentSection';

function Home() {
    return (
        <>
            {home.hero !== undefined && (
                <Hero
                    imgSource={home.hero.imgSource}
                    imgAlt={home.hero.imgAlt}
                >
                    {home.hero.quote !== undefined && (
                        <Blockquote
                            content={home.hero.quote.content}
                            author={`— ${home.hero.quote.author}`}
                        />
                    )}
                </Hero>
            )}
            {home.sections.map((section, i) => {
                return (
                    <ContentSection 
                        key={`${section.title + i}`}
                        title={section.title}
                        paragraphs={section.paragraphs}
                        isIntro={section.isIntro !== undefined && section.isIntro}
                        theme={section.theme}
                        background={section.background}
                    />
                )
            })}
        </>
    )
}

export default Home;