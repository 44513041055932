import styled from 'styled-components';

export const Wrapper = styled.div`
    background: #3772FF;
    color: #FFFFFF;
    padding: 45px 0;
`

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: #FFFFFF;
    
    line-height: 22px;
    @media screen and (max-width: 768px){
        flex-direction: column;
        line-height: 28px;
    }
    a {
        text-decoration: none;
        color: #FFFFFF;
        font-weight: bold;
    }
    div {
        a {
            display: block;
        }
        a:last-child{
            margin-top: 24px;
        }
        @media screen and (max-width: 768px){
            flex-direction: column;
            margin-bottom: 24px;
            a:last-child{
                margin-top: 0;
            }
        }
    }
`