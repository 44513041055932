import styled from 'styled-components';

export const HeroWrapper = styled.div`
    width: 100%;
    height: 500px;
    overflow: hidden;
    background: #000000;
    display: block;
    position: relative;
    border-radius: 24px;
    img {
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: .9;
    }
    div {
        position: relative;
        margin-left: 106px;
        top: 50%;
        transform: translateY(-50%);
        color: #ffffff;
        @media screen and (max-width: 768px){
            margin-left: 0;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
`;