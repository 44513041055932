import React from 'react';
import {Wrapper, ContentWrapper} from './styledComponent';

function ContentSection({
    title,
    paragraphs,
    isIntro,
    theme,
    background,
}){
    return (
        <Wrapper background={background}>
            <ContentWrapper
                isIntro={isIntro}
                theme={theme}
            >
                <h1 
                    dangerouslySetInnerHTML={{
                        __html: title
                    }}
                />
                {paragraphs.map((p, i) => {
                    return(
                        <p 
                            key={`${p + i}`}
                            dangerouslySetInnerHTML={{
                                __html: p
                            }}
                        />
                    )
                })}
            </ContentWrapper>
        </Wrapper>
    )
}

export default ContentSection;