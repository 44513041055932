import React from 'react';
import {HeroWrapper} from './styledComponent';
import {Grid} from '../Grid';

function Hero({imgSource, imgAlt, children}){
    return(
        <Grid>
            <HeroWrapper>
                <img src={imgSource} alt={imgAlt} />
                {children}
            </HeroWrapper>
        </Grid>
    )
}

export default Hero;